import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Video API Service",
  "author": "tzookb",
  "type": "post",
  "date": "2019-05-07T16:58:51.000Z",
  "url": "/2019/05/video-api-service/",
  "categories": ["My Projects"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{` `}<strong parentName="p">{`Tech Stack:`}</strong>{` python, pytest, php, lumen, DynamoDB, AWS, docker, javascript, serverless framework `}</p>
    <h3>{`Description This project got started from an old code base build with clean PHP. We rebuilt this project on lumen framework with PHP, then moved to python with Chalice framework and then “serverless” framework. The stack changed each time with management changes.`}</h3>
    <p>{`This API connected with 4 external APIs and used dynamo as data storage.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      